<style lang="scss" src="./tabs.component.scss"></style>
<template>
  <div class="tabs">
    <div class="tabs__header d-md-none d-block">
      <swiper
          :slides-per-view="2"
          class=""
      >
        <swiper-slide
            v-for="(tab, index) in tabs"
            :key="tab.id"
            @click="selectTab(tab, index)"
            :class="{'tabs__header-item-active': (index+1)===activeTab}"
            class="tabs__header-item"
        >
          {{ tab.title }}
        </swiper-slide>
      </swiper>
    </div>
    <div class="tabs__header d-md-flex d-none">
      <div
          v-for="(tab, index) in tabs"
          :key="tab.id"
          @click="selectTab(tab,index)"
          :class="{'tabs__header-item-active': (index+1)===activeTab}"
          :style="{width:`${100 / this.tabs?.length}%`}"
          class="tabs__header-item">
        {{ tab.title }}
      </div>
    </div>
    <div class="tabs__active d-lg-block d-none">
      <div :style="{
        left: rectanglePosition(),
        width:`${100 / this.tabs?.length}%`
      }" class="rectangle"></div>
    </div>
    <slot></slot>
  </div>
</template>
<script src="./tabs.component.js"></script>