<style lang="scss" src="./card.component.scss"></style>
<template>
  <div class="card">
    <div class="card__info">
      <div class="card__title">
        {{ information.title }}
      </div>
      <div class="card__description mt-15">
        <slot></slot>
      </div>
      <div class="card__date mt-17 mb-5">
        {{ information.date }}
      </div>
    </div>
    <div class="card__download">
      <a :href="information.download" download>
        <img src="@/assets/images/icons/download.svg" alt="">
      </a>
    </div>
  </div>
</template>
<script src="./card.component.js"></script>