export default {
    name: 'tabs-component',
    props: {
        tabs: {
            type: Array,
            require: true
        },
    },
    data() {
        return {
            activeTab: 1,
        };
    },
    methods: {
        selectTab(selectedTab, index) {
            this.activeTab = index + 1;
            this.$emit('selectTab', selectedTab.id);
        },
        rectanglePosition() {
            if (this.activeTab === 1) {
                return 0;
            } else {
                return `${(100 / this.tabs.length) * (this.activeTab - 1)}%`;
            }
        },
    },
};