export default {
    props: {
        title:{
            type:String,
        },
        description:{
            type:String,
        },
        img:{
            type:String,
        }
    }
}