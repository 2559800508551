<style lang="scss" src="./info.component.scss"></style>
<template>
  <div class="information mb-100">
    <div class="row">
      <div class="col-lg-6 col-12">
        <h1 class="text">
      <span
          v-for="(item, index) in title?.split(' ')"
          :key="index"
          class="text">{{ item + ' ' }}
        </span>
        </h1>
        <div class="paragraph mt-30">
          {{description }}
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="about__image img-banner">
          <img :src="img" alt="lead">
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./info.component.js"></script>