export default {
    props: {
        tab: {
            required: true
        },
        selected: {
            type: Number,
        }
    },
}
